import { FunctionComponent } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { PreGameCountDownTimerNumber } from './components/PreGameCountDownTimerNumber';
import { PreGameCountDownWrapper } from './components/PreGameCountDownWrapper';

export interface PreGameCountDownProps {
    duration: number;
    onComplete: () => void;
}

export const PreGameCountDown: FunctionComponent<PreGameCountDownProps> = ({
    duration,
    onComplete,
}) => {
    return (
        <PreGameCountDownWrapper>
            <CountdownCircleTimer
                isPlaying
                duration={duration}
                colors={[
                    ['#004777', 0.33],
                    ['#F7B801', 0.33],
                    ['#A30000', 0.33],
                ]}
                onComplete={onComplete}
            >
                {({ remainingTime }) => (
                    <div className="time-wrapper">
                        <PreGameCountDownTimerNumber>
                            {remainingTime}
                        </PreGameCountDownTimerNumber>
                        <div>Let get ready to rumble</div>
                    </div>
                )}
            </CountdownCircleTimer>
        </PreGameCountDownWrapper>
    );
};
