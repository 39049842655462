import styled from 'styled-components';
import { colors } from '../../shared/colors';

export const StartScreenWrapper = styled.div`
    background-color: ${colors.background};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 100px 50px;
`;
