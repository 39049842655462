import { Badge, List } from 'antd';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useSocket } from '../../../contexts/SocketContext';
import { PlayerStatus } from '../../../enums/PlayerStatus.enum';
import { Button } from '../../shared/components/Button';
import { PlayerData } from '../__types__/PlayerData';
import { GameLobbyWrapper } from './components/GameLobbyWrapper';
import { UserListElement } from './components/UserListElement';

export interface GameLobbyProps {
    players: PlayerData[];
}

export const GameLobby: FunctionComponent<GameLobbyProps> = ({ players }) => {
    const socket = useSocket();
    const me = useMemo<PlayerData | undefined>(
        () => players.find((player) => player.isMe),
        [players]
    );

    const handleReady = useCallback(async () => {
        socket.emit('ready', {}, () => {});
    }, [socket]);

    const handleUnReady = useCallback(async () => {
        socket.emit('unready', {}, () => {});
    }, [socket]);

    if (!me) {
        return null;
    }
    return (
        <GameLobbyWrapper>
            <List
                bordered
                dataSource={players}
                renderItem={(item) => (
                    <UserListElement>
                        {item.nickname}{' '}
                        <Badge
                            status={
                                item.status === PlayerStatus.READY
                                    ? 'processing'
                                    : 'default'
                            }
                        />
                    </UserListElement>
                )}
            />

            <Button
                type="primary"
                danger={me.status === PlayerStatus.READY}
                disabled={
                    players.length !== 2 && me.status !== PlayerStatus.READY
                }
                onClick={
                    me.status === PlayerStatus.READY
                        ? handleUnReady
                        : handleReady
                }
            >
                {me.status === PlayerStatus.READY ? 'Unready' : 'ready'}
            </Button>
        </GameLobbyWrapper>
    );
};
