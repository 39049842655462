import { FunctionComponent } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { headerConstants } from '../headerConstants';

export interface HomeIconProps {
    onClick: () => void;
}

const HomeIconWrapper = styled.div`
    width: ${headerConstants.homeIconWidthInPx}px;
    cursor: pointer;
`;

export const HomeIcon: FunctionComponent<HomeIconProps> = ({ onClick }) => (
    <HomeIconWrapper onClick={onClick}>
        <HomeOutlined />
    </HomeIconWrapper>
);
