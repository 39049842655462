import { FunctionComponent, useEffect } from 'react';
import useWebcam from '../../WebCam';
import { ResultBox } from '../styledComponents/ResultBox';
import { ResultPendingScreenWrapper } from './components/ResultPendingScreenWrapper';

export interface PendingGameScreenProps {
    photo: string | null;
    ownScore: number;
    opponentScore: number;
}

export const ResultPendingScreen: FunctionComponent<PendingGameScreenProps> = ({
    photo,
    ownScore,
    opponentScore,
}) => {
    return (
        <ResultPendingScreenWrapper>
            {photo ? (
                <img src={photo} style={{margin: "10px 0"}}/>
            ) : (
                <div>Check Your Camera Access!!!!!</div>
            )}

            <ResultBox>
                Waiting for results
                <br />
                {ownScore} : {opponentScore}
            </ResultBox>
        </ResultPendingScreenWrapper>
    );
};
