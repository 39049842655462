import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useSocket } from '../../../contexts/SocketContext';
import { redirectToStart } from '../../../routing/commands/redirectToStart';
import { HeaderWrapper } from './components/HeaderWrapper';
import { HomeIcon } from './components/HomeIcon';

export const Header: FunctionComponent<{}> = () => {
    const history = useHistory();
    const socket = useSocket();

    return (
        <HeaderWrapper>
            <HomeIcon onClick={() => redirectToStart(history, socket)} />
            <span>Rock Paper Scissors</span>
        </HeaderWrapper>
    );
};
