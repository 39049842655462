import { FunctionComponent, useCallback, useState } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { redirectToGameScreen } from '../../routing';
import { Button, Input } from '../shared/sharedComponents';
import { StartScreenLogo } from './components/StartScreenLogo';
import { StartScreenWrapper } from './components/StartScreenWrapper';
import { useSocket } from '../../contexts/SocketContext';
import { joinRoom } from '../../api/socket/joinRoom';
import { useAppContext } from '../../contexts/AppContext';

export const StartScreen: FunctionComponent = () => {
    const history = useHistory();
    const socket = useSocket();
    const { nickname } = useAppContext();
    const [roomName, setRoomName] = useState<string>();

    const handleButtonClick = useCallback(async () => {
        if (!roomName) {
            message.error('invalid room name');
            return;
        }
        
        try {
            await joinRoom(socket, roomName, nickname);
            console.log('redi2')
            redirectToGameScreen(history);
        } catch (err) {
            message.error(err.message);
        }
    }, [socket, nickname, roomName, history]);

    return (
        <StartScreenWrapper>
            <StartScreenLogo />
            <div>
                <Input
                    placeholder="Room name"
                    required={true}
                    autoComplete="false"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                />
                <Button type="primary" onClick={() => handleButtonClick()}>
                    Join Room
                </Button>
                <span>v0.4</span>
            </div>
        </StartScreenWrapper>
    );
};
