import { notification } from 'antd';
import { FunctionComponent, useEffect } from 'react';
import useWebcam from '../../WebCam';
import { ResultBox } from '../styledComponents/ResultBox';
import { PendingGameScreenWrapper } from './components/PendingGameScreenWrapper';

export interface PendingGameScreenProps {
    onPhoto(base64: string | null): Promise<void>;
    ownScore: number;
    opponentScore: number;
}

export const PendingGameScreen: FunctionComponent<PendingGameScreenProps> = ({
    onPhoto,
    ownScore,
    opponentScore,
}) => {
    const [WebCam, takeScreenShot] = useWebcam();
    const timeout = 5;

    useEffect(() => {
        setTimeout(() => {
            const screenShot = takeScreenShot();
            if (!screenShot) {
                notification.error({ message: "Couldn't access camera!" });
            }
            onPhoto(screenShot);
        }, timeout * 1000);
    }, []);

    return (
        <PendingGameScreenWrapper>
            <WebCam />
            <ResultBox>
                Result
                <br />
                {ownScore} : {opponentScore}
            </ResultBox>
        </PendingGameScreenWrapper>
    );
};
