import { Socket } from 'socket.io-client';

interface JoinPayload {
    room: string;
    nickname: string;
}

export interface JoinRoomResponse {
    playersCount: 0 | 1;
}

export const joinRoom = async (
    socket: Socket,
    room: string,
    nickname: string
): Promise<JoinRoomResponse> =>
    new Promise((resolve, reject) => {
        const payload: JoinPayload = { room, nickname };
        socket.emit('join', payload, (callback: any) => {
            if (callback.status === 'ERROR') {
                reject({ message: callback.message });
            }
            console.log(callback);
            resolve({ playersCount: callback.playersCount });
        });
    });
