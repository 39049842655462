import styled from 'styled-components';
import { Header } from 'antd/lib/layout/layout';
import { colors } from '../../../shared/colors'
import { headerConstants } from '../headerConstants';

export const HeaderWrapper = styled(Header)`
    background-color: ${colors.primary};
    height: ${headerConstants.headerHeightInPx}px;
    color: ${colors.highlightedText};
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    
    span {
        margin-right: ${headerConstants.homeIconWidthInPx}px;
        width: 100%;
        text-align: center;
    }
`;
