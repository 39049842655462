import { FunctionComponent } from 'react';
import { GameResult } from '../../../enums/GameReuslt.enum';
import { ResultBox } from '../styledComponents/ResultBox';
import { ResultScreenWrapper } from './components/ResultScreenWrapper';

export interface ResultScreenProps {
    ownResult: number;
    opponentResult: number;
    opponentBase64Image: string | null;
    ownSymbol: string;
    opponentSymbol: string;
    result: GameResult;
}

export const ResultScreen: FunctionComponent<ResultScreenProps> = ({
    opponentBase64Image,
    opponentResult,
    opponentSymbol,
    ownResult,
    ownSymbol,
    result,
}) => {
    return (
        <ResultScreenWrapper>
            {opponentBase64Image ? (
                <img src={opponentBase64Image} style={{ margin: '10px 0' }} />
            ) : (
                <div>Check Your Camera Access!!!!!</div>
            )}

            <ResultBox>
                {ownSymbol}
                <br />
                {result === GameResult.LOSE && 'got broken by'}
                {result === GameResult.TIE && 'versus'}
                {result === GameResult.WIN && 'breaks'}
                <br />
                {opponentSymbol}
                <br />
                {ownResult} : {opponentResult}
            </ResultBox>
        </ResultScreenWrapper>
    );
};
