import { createContext, useContext } from 'react';

export interface AppContext {
    nickname: string;
    setNickname: (name: string) => void;
}

export const ReactAppContext = createContext<AppContext>({
    nickname: `username${Math.random() * 50000}`,
    setNickname: ()=>{}
});

export const useAppContext = () => useContext(ReactAppContext);
