import React, { FunctionComponent, useRef } from 'react';
import { WebCamComponent, WebCamProps } from '../components/WebCamComponent';
import { TakeScreenshot } from '../__types__/TakeScreenshot';
import { WebCamRef } from '../__types__/WebCamRef';
import { getScreenshotTaker } from './getScreenshotTaker';

export const useWebcam = (): [
    FunctionComponent<Omit<WebCamProps, 'webCamRef'>>,
    TakeScreenshot,
    WebCamRef
] => {
    const ref = useRef<WebCamRef>(null);
    return [() => <WebCamComponent webCamRef={ref} />, getScreenshotTaker(ref), ref];
};
