import { Content } from 'antd/lib/layout/layout';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { headerConstants } from '../Header/headerConstants';

const ContentWrapperComponent = styled(Content)`
    height: calc(100% - ${headerConstants.headerHeightInPx}px);
    overflow: hidden;
`;

export const ContentWrapper: FunctionComponent = ({ children }) => {
    return <ContentWrapperComponent>{children}</ContentWrapperComponent>;
};
