import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { io } from 'socket.io-client';
import { Switch, Route, useHistory } from 'react-router-dom';
import './App.css';
import { GameScreen } from './modules/GameScreen/GameScreen';
import { Header } from './modules/App/Header/Header';
import { ContentWrapper } from './modules/App/Content/ContentWrapper';
import { redirectToStart, routePaths } from './routing';
import { StartScreen } from './modules/StartScreen/StartScreen';
import { SocketContext } from './contexts/SocketContext';
import { config } from './config/config';
import { AppContext, ReactAppContext } from './contexts/AppContext';

export const App: FunctionComponent = () => {
    const socket = useMemo(() => io(config.apiUrl), []);
    const history = useHistory();
    const [nickname, setStateNickname] = useState(
        localStorage.getItem('nickname') || `nickname${Math.random() * 50000}`
    );
    const setNickname = useCallback(
        (nickname: string) => {
            localStorage.setItem('nickname', nickname);
            setStateNickname(nickname);
        },
        [setStateNickname]
    );
    const appContext = useMemo<AppContext>(
        () => ({ nickname, setNickname }),
        [nickname, setNickname]
    );

    useEffect(() => {
        socket.on('notInRoom', () => {
            redirectToStart(history, socket);
        });

        return () => {
            socket.off('notInRoom');
        };
    }, [socket, history]);

    return (
        <SocketContext.Provider value={socket}>
            <ReactAppContext.Provider value={appContext}>
                <Header />
                <ContentWrapper>
                    <Switch>
                        <Route path={routePaths.game}>
                            <GameScreen />
                        </Route>
                        <Route path={routePaths.start} strict={true}>
                            <StartScreen />
                        </Route>
                    </Switch>
                </ContentWrapper>
            </ReactAppContext.Provider>
        </SocketContext.Provider>
    );
};

export default App;
