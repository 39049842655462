import React, { FunctionComponent, RefObject } from 'react';
import WebCamLibComponent from 'react-webcam';

export interface WebCamProps {
    webCamRef: RefObject<WebCamLibComponent>;
}

export const WebCamComponent: FunctionComponent<WebCamProps> = ({ webCamRef }) => {
    return <WebCamLibComponent  audio={false} mirrored={true} screenshotFormat="image/jpeg" ref={webCamRef} />;
};
