import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useAppContext } from '../../contexts/AppContext';
import { GameStateContext } from '../../contexts/GameStateContext';
import { useSocket } from '../../contexts/SocketContext';
import { GameState } from '../../enums/GameState.enum';
import { GameLobby } from './Lobby/GameLobby';
import { GameScreenWrapper } from './styledComponents/Wrapper';
import { GameStartPayload } from './__types__/GameStartPayload';
import { ListPlayersPayload } from './__types__/ListPlayersPayload';
import { PlayerData } from './__types__/PlayerData';
import { UserJoinedPayload } from './__types__/UserJoinedPayload';
import { UserLeftPayload } from './__types__/UserLeftPayload';
import { UserStatusPayload } from './__types__/UserStatusPayload';
import { PreGameCountDown } from './PreGameCountDown/PreGameCountDown';
import { PendingGameScreen } from './PendingGameScreen/PendingGameScreen';
import { AssumptionCallbackPayload } from './__types__/AssumptionCallbackPayload';
import { ResultPendingScreen } from './ResultPendingScreen/ResultPendingScreen';
import { GameResult } from '../../enums/GameReuslt.enum';
import { ResultScreen } from './ResultScreen/ResultScreen';

export const GameScreen: FunctionComponent = () => {
    const history = useHistory();
    const { nickname: userNickname } = useAppContext();
    console.log('loc ', history.location);
    const [players, setPlayers] = useState<PlayerData[]>([]);
    const [gameState, setGameState] = useState<GameState>(GameState.LOBBY);
    const socket = useSocket();
    const [gameCountDownLength, setGameCountDownLength] = useState(0);
    const [[ownScore, opponentScore], setResult] = useState([
        { result: 0, symbol: '' },
        { result: 0, symbol: '' },
    ]);
    const [ownPhoto, setOwnPhoto] = useState<string | null>(null);
    const [opponentsPhoto, setOpponentsPhoto] = useState<string | null>(null);
    const [roundResult, setRoundResult] = useState<GameResult | null>(null);

    useEffect(() => {
        socket.emit('playersList', {}, ({ players }: ListPlayersPayload) => {
            setPlayers(
                players.map((player) => ({
                    ...player,
                    isMe: player.nickname === userNickname,
                }))
            );
        });

        socket.on('userJoined', ({ nickname, status }: UserJoinedPayload) => {
            console.log(`${nickname} joined`);
            setPlayers((players) => [
                ...players,
                { nickname, status, isMe: false },
            ]);
        });

        socket.on('lobby', () => {
            setGameState(GameState.LOBBY);
            setResult([
                { result: 0, symbol: '' },
                { result: 0, symbol: '' },
            ]);
        });

        socket.on('userStatus', ({ nickname, status }: UserStatusPayload) => {
            console.log(`${nickname} statused: ${status}`);
            setPlayers((players) => {
                const statusedIndex = players.findIndex(
                    (player) => player.nickname === nickname
                );

                const newPlayers = [...players];
                newPlayers[statusedIndex].status = status;
                return newPlayers;
            });
        });

        socket.on('userLeft', ({ nickname }: UserLeftPayload) => {
            console.log(`${nickname} left`);
            setPlayers((players) =>
                players.filter((player) => player.nickname !== nickname)
            );
        });

        socket.on('startGame', ({ countdown }: GameStartPayload) => {
            console.log('startingGame');
            setGameCountDownLength(countdown);
            setGameState(GameState.PRE_GAME_COUNTDOWN);
        });

        socket.on('gameStatus', (a: any) => {
            console.log(`${a} status`);
            if (a.status === 'PENDING') {
                setGameState(GameState.PENDING);
            }
        });
        return () => {
            socket.off('userJoined');
            socket.off('userLeft');
            socket.off('userStatus');
            socket.off('startGame');
            socket.off('gameStatus');
        };
    }, [socket, userNickname]);

    return (
        <GameStateContext.Provider value={gameState}>
            <GameScreenWrapper>
                {gameState === GameState.LOBBY && (
                    <GameLobby players={players} />
                )}
                {gameState === GameState.PRE_GAME_COUNTDOWN && (
                    <PreGameCountDown
                        duration={gameCountDownLength}
                        onComplete={() => {}}
                    />
                )}
                {gameState === GameState.PENDING && (
                    <PendingGameScreen
                        onPhoto={async (base64) => {
                            setGameState(GameState.RESULT_PENDING);
                            setOwnPhoto(base64);
                            socket.emit('symbolImage', { image: base64 });
                            socket.once(
                                'roundResult',
                                ({
                                    ownResult,
                                    opponentResult,
                                    opponentBase64Image,
                                    opponentSymbol,
                                    ownSymbol,
                                    tie,
                                    win,
                                }: AssumptionCallbackPayload) => {
                                    console.log('result');
                                    setGameState(GameState.ROUND_RESULT);
                                    setResult([
                                        {
                                            result: ownResult,
                                            symbol: ownSymbol,
                                        },
                                        {
                                            result: opponentResult,
                                            symbol: opponentSymbol,
                                        },
                                    ]);
                                    setOpponentsPhoto(opponentBase64Image);

                                    let result: GameResult = GameResult.LOSE;
                                    if (tie) {
                                        result = GameResult.TIE;
                                    }

                                    if (win) {
                                        result = GameResult.WIN;
                                    }

                                    setRoundResult(result);
                                }
                            );
                        }}
                        ownScore={ownScore.result}
                        opponentScore={opponentScore.result}
                    />
                )}
                {gameState === GameState.RESULT_PENDING && (
                    <ResultPendingScreen
                        photo={ownPhoto}
                        ownScore={ownScore.result}
                        opponentScore={opponentScore.result}
                    />
                )}
                {gameState === GameState.ROUND_RESULT && (
                    <ResultScreen
                        ownResult={ownScore.result}
                        ownSymbol={ownScore.symbol}
                        opponentBase64Image={opponentsPhoto}
                        opponentResult={opponentScore.result}
                        opponentSymbol={opponentScore.symbol}
                        result={roundResult!}
                    />
                )}
            </GameScreenWrapper>
        </GameStateContext.Provider>
    );
};
